module.exports = new Promise ((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    let remoteUrlWithVersionMA360;
    
    remoteUrlWithVersionMA360 = "http://localhost:3003/remoteEntry.js";//"https://qa-exlvantage-MA360.exlservice.com/remoteEntry.js";
    const script = document.createElement("script");

    switch (env) {
        case "local":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
          break;
        case "dev":
            remoteUrlWithVersionMA360 =
                "https://azvantagedev-ma.exlservice.com/remoteEntry.js";
           break;
        case "qa":
            remoteUrlWithVersionMA360 =
                "https://qa-exlvantage-ma.exlservice.com/remoteEntry.js";
           break;
        case "uat":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktuat":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "idemo":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktuattest":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        case "lktprod":
            remoteUrlWithVersionMA360 = "http://localhost:3000/remoteEntry.js";
           break;
        default:
            remoteUrlWithVersionMA360 = "";
            break;
    }
    script.src = remoteUrlWithVersionMA360;
    script.onload = () => {
        // the injected script has loaded and is available on window
        // we can now resolve this Promise
        const proxy = {
            get: (request) => window.MA360.get(request),
            init: (arg) => {
                try {
                    console.log("argument", arg);
                    return window.MA360.init(arg);
                } catch (e) {
                    console.log("remote container already initialized");
                }
            },
        };
        resolve(proxy);
    };
    script.onerror = () => {
        reject();
        //alert("Error loading " + this.src); // Error loading https://example.com/404.js
        document.head.appendChild(null);
        //return null;
    };
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);
});